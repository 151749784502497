/**
 * We'll load Lodash, a modern JavaScript utility library delivering modularity, performance & extras.
 * Lodash makes JavaScript easier by taking the hassle out of working with arrays, numbers, objects, strings, etc.
 * Lodash’s modular methods are great for: Iterating variables, Manipulating & testing values, Creating composite functions.
 */
window._ = require('lodash');

/**
 * Solve problems with old browsers with es6 promise polyfill
 */
require('es6-promise').polyfill();

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

jQuery(document).ready(function ($) {
    // Your custom code...
    console.log('File successfully loaded!');

    const ajaxUrl = ajax_bones.ajaxurl;
    const ajaxNonce = ajax_bones.nonce;

    // Init wow Js
    var wow = new WOW({
        boxClass:     'wow',      // animated element css class (default is wow)
        animateClass: 'animated', // animation css class (default is animated)
        mobile:       false,       // trigger animations on mobile devices (default is true)
        live:         false,       // act on asynchronously loaded content (default is true)
        resetAnimation: false,     // reset animation on end (default is true)
    });
    wow.init();

    // News slider
    var swiper = new Swiper('.swiper-news', {

        slidesPerView: 2,
        spaceBetween: 20,
        centeredSlides: true,
        initialSlide: 2,
        loop: true,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        breakpoints: {
            640: {
                slidesPerView: 2,
                spaceBetween: 30,
                centeredSlides: true,
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 20,
            }
        }

    });

    // Sostenibillidad home Slider
    var swiper = new Swiper('.swiper-sustainability', {
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        }
    });


    $('.mobile-icon').click(function(){
        $('.main-mobile').slideToggle();
    });

    $('#search').click(function(){
        $('.form-search').toggle();
    });

    $('#search-web').click(function(){
        $('.form-search').toggle();
    });

    $('.services-main').click(function (e) { 
        e.preventDefault();
        e.stopPropagation();
        if($('.services').length){
            $("html, body").animate({
                scrollTop: $('.services').offset().top 
            }, 2000);
        }else{
            window.location.href = "/";
        }
        
    });

    // Maps
    if( $('#map-countries').length ){
        var map = new Datamap({
            element: document.getElementById('map-countries'),
            responsive: false,
            setProjection: function(element) {
                var projection = d3.geo.equirectangular()
                    .center([100, 0])
                    .rotate([175, 0])
                    .scale(500)
                    .translate([element.offsetWidth / 2, element.offsetHeight / 2]);
                var path = d3.geo.path()
                .projection(projection);
                return {path: path, projection: projection};
            },
            fills: {
                COL: '#003c83',
                HND: '#2170b7',
                SLV: '#4f94c6',
                PAN: '#2170b7',
                PER: '#2170b7',
                CHL: '#68aac1',
                UNKNOWN: 'rgb(0,0,0)',
                defaultFill: '#bfd9ef'
            },
            data: {
                COL: {
                    fillKey: 'COL',
                    icon: '/wp-content/uploads/2019/12/tool-col.png'
                },
                HND: {
                    fillKey: 'HND',
                    icon: '/wp-content/uploads/2019/12/tool-honduras.png'
                },
                SLV: {
                    fillKey: 'SLV',
                    icon: '/wp-content/uploads/2019/12/tool-salvador-1.png'
                },
                PAN: {
                    fillKey: 'PAN',
                    icon: '/wp-content/uploads/2019/12/tool-panama.png'
                },
                PER: {
                    fillKey: 'PER',
                    icon: '/wp-content/uploads/2019/12/tool-peru.png'
                },
                CHL: {
                    fillKey: 'CHL',
                    icon: '/wp-content/uploads/2019/12/tool-chile.png'
                }
            },
            geographyConfig: {
                hideAntarctica: true,
                borderWidth: 1,
                borderOpacity: 1,
                borderColor: '#FDFDFD',
                popupTemplate: function(geo, data) {
                    return ['<img src="'+ data.icon +'" src="Tool Map">'].join('');
                },
                popupOnHover: false,
                highlightOnHover: true
            }
        });
        //map.labels();
    }

    // News slider
    var swiperNational = new Swiper('.swiper-national-services', {
        slidesPerView: 3,
        centeredSlides: false,
        spaceBetween: 30,
        loop: false,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        pagination: {
            el: '.swiper-pagination-where',
            clickable: true,
        },
        breakpoints: {
            640: {
                slidesPerView: 1,
                spaceBetween: 5,
                centeredSlides: false,
                initialSlide: 1,
                navigation: false
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 20,
                navigation: {
                    nextEl: '',
                    prevEl: '',
                }
            }
        },
        on: {
            slideChange: function () {
                setTimeout(() => {
                    let activeSlide = $('.swiper-slide-active').find('article span.map-iframe');
                    $('.map-result .map').html($(activeSlide).eq(0).html());
                }, 500);
            },
        }
    });

    
    // Contact form 7 
    if($('.wpcf7').length){
        $('.wpcf7-submit').val('Envíar');
        var wpcf7Elm = document.querySelector( '.wpcf7' ); 
        wpcf7Elm.addEventListener( 'wpcf7mailsent', function( event ) {
            $("html, body").animate({
                scrollTop: $('.form').offset().top 
            }, 2000);
        }, false );
    }

    // 
    $('#department-fil').on('change', function(e) {
        e.preventDefault();
        var id_parent_cat = $($(this)).val();

        if(id_parent_cat){
            $.ajax({
                url: ajaxUrl,
                type: 'POST',
                dataType: 'json',
                data: {
                    action: 'get_municipalities',
                    nonce: ajaxNonce,
                    data: id_parent_cat,
                },
                beforeSend: function () {
                    //console.log('Cargando...');
                },
            })
            .done(function (response) {
                $("#municipality-fil").html('<option value="">Seleccione...</option>');
                $.each(response.data, function(key, value) {
                    $("#municipality-fil").append('<option value='+ value.term_id +'>'+ value.name +'</option>');
                    $('#municipality-fil').prop('disabled', false);
                }); 
            })
            .fail(function (response) {
                console.log('error');
            });
        }

    });

     // 
     $('#municipality-fil').on('change', function(e) {
        e.preventDefault();

        var id_child_cat = $($(this)).val();

        if(id_child_cat){
            $.ajax({
                url: ajaxUrl,
                type: 'POST',
                dataType: 'json',
                data: {
                    action: 'get_info_location',
                    nonce: ajaxNonce,
                    data: id_child_cat,
                },
                beforeSend: function () {
                    //console.log('Cargando...');
                },
            })
            .done(function (response) {
                console.log(response.data);
                $('#total_result span').html(response.data.length);
                if(response.data[0].map){
                    $('.map-result .map').html(response.data[0].map);
                }

                $.each(response.data, function(key, value) {
                    var url_ext = '';
                    var image = '';
                    if(value.url_site ){
                        url_ext = `<a href="${value.url_site}" target="_blank">Sitio web</a>`;
                    }
                    if( value.image ){
                        image = `<img src="${value.image}" alt="">`;
                    }else{
                        image = `<img src="/wp-content/uploads/2019/12/bg-default-location-n.png" alt="">`;
                    }
                    const post = `
                        <div class="swiper-slide">
                            <article>
                                <figure>
                                    ${image}
                                    <div class="overlay">
                                        <h2>${value.title}</h2>
                                    </div>
                                </figure>
                                <div class="info">
                                    <div class="address">
                                        <h5>Dirección</h5>
                                        <p>${value.address}</p>
                                    </div>
                                    <div class="phone">
                                        <h5>Teléfono</h5>
                                        <p>${value.phone}</p>
                                    </div>
                                    ${url_ext}
                                    <span class="map-iframe" style="display: none;">
                                        ${value.map}
                                    </span>
                                </div>
                            </article>
                        </div>
                    `;
                    $(".swiper-national-services .swiper-wrapper").html(post);
                });
                swiperNational.update();
            })
            .fail(function (response) {
                console.log('error');
            });
        }
    });

     /**
     * Gallery Services
     */
    if( jQuery(".gallery-services").length !== 0 ) {

        
        $('.gallery-services-img').magnificPopup({
            type: 'image',
            gallery:{
                enabled:true,
                tCounter: '%curr% de %total%'
            }
        });
    
        AOS.init();
    }

    if( jQuery('.slider-home').length !== 0 ) {
        jQuery('.carousel').carousel({
          interval: false
        });

        jQuery('#carouselControls').on('slide.bs.carousel', function (e) {
          var $target = jQuery(this);
          var carruselID = jQuery('#'+e.relatedTarget.id);
          console.log(carruselID);
          if(carruselID.data('content') != 'video'){
              $target.find('iframe').attr('src',"");  
              $target.find('video').attr('src', "");  

          }else{
            $target.find('video').attr('src',carruselID.data('mp4'));
            $target.find('iframe').attr('src','https://www.youtube.com/embed/' + carruselID.data('link'));

          }
        })
  }



});
